export const clientLogos = [
  { mainpage: true, src: "assets/images/clientLogo/Picture1.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture2.png" },
  { mainpage: true, src: "assets/images/clientLogo/Picture19.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture5.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture6.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture7.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture8.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture9.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture10.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture11.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture12.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture13.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture14.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture15.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture16.png" },
  { mainpage: true, src: "assets/images/clientLogo/Picture3.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture18.png" },
  { mainpage: true, src: "assets/images/clientLogo/Picture17.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture20.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture21.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture22.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture23.png" },
  { mainpage: false, src: "assets/images/clientLogo/Picture24.png" },

  { mainpage: false, src: "assets/images/clientLogo/Picture26.png" },
];
