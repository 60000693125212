export const projects = [
  {
    name: "Afton Properties Various Projects",
    location: "Los Angeles County, CA Riverside County, CA ",
  },
  { name: "Caltrans District 7", location: "Los Angeles, CA " },
  {
    name: "Cirrus Asset Management Various Projects",
    location: "Los Angeles County, CA",
  },
  {
    name: "Califonia State University ",
    location: "Los Angeles, CA",
  },
  {
    name: "Department of Veterans Affairs",
    location: "Loma Linda, CA",
  },
  {
    name: "Elihu Harris State Building Barrier Removal",
    location: "Oakland, CA",
  },
  {
    name: "Frank R Boweman Landfill Drainage Repairs",
    location: "Irvine, CA",
  },
  {
    name: "ICO Group Co Various project",
    location: "Los Angeles County, CA",
  },
  {
    name: "Los Angeles County ISD",
    location: "Los Angeles County, CA",
  },
  {
    name: "Moss and Company",
    location: "Los Angeles, CA",
  },
  {
    name: "MJW Investments Various Projects",
    location: "Los Angeles County, CA",
  },
  {
    name: "OC Public Works Various Project",
    location: "Orange County, CA",
  },
  {
    name: "Ondemand Korea T.I.",
    location: "Fullerton, CA",
  },
  {
    name: "Osen Restaurant",
    location: "Santa Monica, CA",
  },
  {
    name: "Peak Asset Management Various Project",
    location: "Los Angeles County, CA",
  },
  {
    name: "Porterville Developmental Center",
    location: "Porterville, CA",
  },
  {
    name: "R&R Surgical Center",
    location: "Torrance, CA",
  },
  {
    name: "Red Point Realty Various Projects",
    location: "Orange County, CA",
  },
  {
    name: "San Diego Public Works",
    location: "San Diego, CA",
  },
  {
    name: "Santa Monica College Art Building ",
    location: "San Monica, CA",
  },
  {
    name: "Sea Pac Engineering Various project",
    location: "Los Angeles, CA",
  },
  {
    name: "Sitco Properties",
    location: "Los Angeles, CA",
  },
  {
    name: "The Code Solution",
    location: "Long Beach, CA",
  },
  {
    name: "Wilton Apartments",
    location: "Los Angeles, CA",
  },
];
